import React, { Suspense, useEffect } from "react"
import HelmetProvider from "react-navi-helmet-async"
import { NotFoundBoundary, Router, View } from "react-navi"
import { ContainerProvider } from "./services/containerProvider"
import { I18nProvider } from "./i18n/I18nProvider"
import { routes } from "./routes"
import { PageLoading } from "./components/partial/PageLoading/PageLoading"
import { NotFound } from "./pages/Error/NotFound"
import liff from "@line/liff"

export const App = (): JSX.Element => {

  useEffect(() => {
    const initializeLiff = async () => {
      try {
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          console.log(profile);
          window.profile = profile;
        }
      } catch (err) {
        alert("Line liff getProfile failed:" + err);
      }
    };
    initializeLiff();
  }, []);

  return (
    <ContainerProvider>
      <HelmetProvider>
        <Router routes={routes}>
          <Suspense fallback={<PageLoading isLoading={true} />}>
            <I18nProvider>
              <NotFoundBoundary render={NotFound}>
                <View />
              </NotFoundBoundary>
            </I18nProvider>
          </Suspense>
        </Router>
      </HelmetProvider>
    </ContainerProvider>
  )
}
